








































import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import { namespace } from 'vuex-class';
import { ADD_HEALTH_INSURANCE_MEMBERSHIP } from '../../../store/actions';
import { AddHealthInsuranceMembership, ConservatorshipDetails } from '@/modules/conservatorship/types';
import { ApiResponse } from '@/components/types';
import HealthInsuranceSelect from '@/modules/contact/components/HealthInsuranceSelect.vue';

const Conservatorship = namespace('conservatorship');

@Component({
  components: {
    HealthInsuranceSelect
  }
})
export default class AddHealthInsurancesMembershipDialog extends Vue {
  @Prop({ type: Object, required: true }) conservatorship!: ConservatorshipDetails;
  @Conservatorship.Action(ADD_HEALTH_INSURANCE_MEMBERSHIP) addHealthInsurancesMembership!: (params: AddHealthInsuranceMembership) => Promise<ApiResponse>;

  dialog: boolean = false;
  error: any = null;

  value: AddHealthInsuranceMembership = {
    conservatee_id: this.conservatorship.id,
    health_insurance_id: '',
    policy_number: ''
  };

  @Watch('dialog')
  watchDialogClosed(dialog: boolean) {
    if (dialog) {
      this.value = {
        conservatee_id: this.conservatorship.id,
        health_insurance_id: '',
        policy_number: ''
      };

      if (this.conservatorship.healthInsuranceMembership) {
        this.value.health_insurance_id = this.conservatorship.healthInsuranceMembership.healthInsuranceId;
        this.value.policy_number = this.conservatorship.healthInsuranceMembership.policyNumber;
      }

      return;
    }

    setTimeout(() => {
      // @ts-ignore
      this.$refs.form.reset();
      this.error = null;
    }, 200);
  }

  save() {
    this.error = null;

    return this.addHealthInsurancesMembership(this.value);
  }
}
