




















import { Component, Prop, Vue } from 'vue-property-decorator';
import { namespace } from 'vuex-class';
import { FETCH_CONTACT_LIST, GET_HEALTH_INSURANCE_LIST } from '../store';
import { Contact } from '../types';
import { ApiResponse } from '@/components/types';

const HealthInsurance = namespace('contact');

@Component({})
export default class HealthInsuranceSelect extends Vue {
  @Prop({ type: String }) value?: string;
  @Prop({ type: Boolean, default: true }) required!: boolean;

  @HealthInsurance.Getter(GET_HEALTH_INSURANCE_LIST) list!: Contact[];
  @HealthInsurance.Action(FETCH_CONTACT_LIST) fetch!: () => Promise<ApiResponse>;

  loading: boolean = false;

  async created() {
    if (this.list.length !== 0) return;

    this.loading = true;
    await this.fetch();
    this.loading = false;
  }
}
